import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

class Anakoinwseistwo extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="columns is-multiline">
        {posts &&
          posts.map(({ node: post }) => (
            <div className="is-parent column is-63" key={post.id}>
              <article
                className={`anakoinwseis-list-item tile is-child box notification3`}
              >
                <header>

                  <p className="post-meta">
                    <div
                      className="title has-text-primary is-size-4"
                      to={post.fields.slug}
                    >
                      {post.frontmatter.title}
                    </div>
                    <span></span>

                  </p>
                </header>

<div dangerouslySetInnerHTML={{ __html: post.html}} />
                  <br />
                  <br />

              </article>
            </div>
          ))}
      </div>
    )
  }
}

Anakoinwseistwo.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query AnakoinwseistwoQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "anakoinwseis-post" } } }
        ) {
          edges {
            node {
              html
              excerpt(pruneLength: 1000)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <Anakoinwseistwo data={data} count={count} />}
  />
)
