import React from 'react'

import Layout from '../../components/Layout'

import Anakoinwseistwo from '../../components/AnakoinwseisRolltwo'

export default class AnakoinwseisIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section" style={{minHeight:'70vh'}}>
          <div className="container3">
            <div className="content">
            <div className="fixed">
            <h3 className="title is-size-3 has-text-weight-bold is-bold-light">
              ΑNAKOINΩΣΕΙΣ
            </h3>
            <hr/>
            <br/>

              <Anakoinwseistwo/>
            </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
